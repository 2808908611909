import React from "react";
import Post from "../components/Post";

export default function HumbleBeginnings() {
  return (
    <Post
      title={"the beginnings of one humble potato"}
      postDate={"july 26, 2021"}
    >
      <p>
        Welcome to one humble potato! This is a website about video games, it
        will mostly contain blog style posts, but there is also a youtube
        channel that will be populated with various videos from my gaming
        adventures. It may also contain some posts about game development, and
        game jams.
      </p>

      <p>
        I play a variety of games, but not much from the mainstream. There are
        already lots of places where you can get news and updates about those
        games already, and that’s not really what I’m into anyway. This site
        will feature posts about a lot of retro-ish games (early 2000s), early
        access crafting/sim games, and other random indie titles that look
        interesting to me.
      </p>

      <p>
        This is an exciting era if you are into video games of any kind so I
        hope you find something you like here! If you have any suggestions or
        comments{" "}
        <a
          href="https://discord.gg/pyCTjnUhdk"
          target="_blank"
          rel="noreferrer"
        >
          drop in my discord server
        </a>{" "}
        and say “hello!”
      </p>
    </Post>
  );
}
